<template>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="213px" style="width: 600px; margin-left:50px;" class="demo-ruleForm">
    <el-form-item label="客服离线是自动上线接待访客" prop="status" label-width="213px">
      <el-radio-group v-model="ruleForm.status">
        <el-radio-group v-model="ruleForm.status" size="medium">
          <el-radio border :label="0">是</el-radio>
          <el-radio border :label="1">否</el-radio>
        </el-radio-group>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="机器人名称" prop="robotname">
      <el-input v-model="ruleForm.robotname" ></el-input>
    </el-form-item>
    <el-form-item label="机器人图片" prop="robotimgurl">
    <el-upload
      class="avatar-uploader"
      :http-request="fileUploadFunction"
       action=""
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload">
      <img v-if="robotimgurl" :src="robotimgurl" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    </el-form-item>
    <el-form-item label="欢迎语" prop="welcomemessage">
      <el-input type="textarea" v-model="ruleForm.welcomemessage"></el-input>
    </el-form-item>
    <el-form-item label="无答案时回复" prop="noanswerto">
      <el-input type="textarea" v-model="ruleForm.noanswerto"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import {getrobot,addorupdateProject} from "@/apis/robot";
  export default {
    name: "robotInfo",
    data() {
      return {
        ruleForm: {
          robotid: '',
          robotname: '',
          robotimgurl: '/images/robot.jpg',
          status: 0,
          welcomemessage: '',
          noanswerto: '',
          auid:null
        }
        ,robotimgurl:null,
        rules: {
          robotname: [
            { required: true, message: '请输入机器人名称', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ],
          robotimgurl: [
            { required: true, message: '请上传机器人头像', trigger: 'change' }
          ],
          status: [
            { required: true, message: '请选择上线状态', trigger: 'change' }
          ],
          welcomemessage: [
            { required: true, message: '请填写欢迎语', trigger: 'change' }
          ],
          noanswerto: [
            { required: true, message: '请填写无答案回复', trigger: 'change' }
          ]
        }
      };
    },
    created() {
      this.getRobot();
    },
    methods: {
      getRobot() {
        this.ruleForm.auid=this.user.userId
        getrobot(this.ruleForm).then(res => {
          if (res.retdata){
            this.ruleForm=res.retdata;
            this.robotimgurl= res.retdata.robotimgurl;
          }
        })
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid){
            this.ruleForm.auid=this.user.userId
            addorupdateProject(this.ruleForm).then(res =>{
              this.$message.success(res.retDesc);
              this.getRobot();
            })
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },handleAvatarSuccess(res, file) {
        this.ruleForm.robotimgurl=res.src;
        this.robotimgurl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 或者 PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      }
    }
  }
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9 !important;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
